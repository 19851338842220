
import { HttpClient } from "../HttpClient";
/**
 * * Get Average Issuance Time
 */
export const GetAverageIssuanceTime = async () => {
  try {
    const { data } = await HttpClient.get("api/contents/AverageIssuanceTime");
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};
